define("discourse/plugins/discourse-adplugin/discourse/routes/admin-plugins-house-ads-index", ["exports", "@ember/object", "@ember/service", "discourse/routes/discourse"], function (_exports, _object, _service, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _discourse.default.extend((_obj = {
    router: (0, _service.inject)(),
    moreSettings() {
      this.router.transitionTo("adminSiteSettingsCategory", "ad_plugin");
    }
  }, (_applyDecoratedDescriptor(_obj, "moreSettings", [_object.action], Object.getOwnPropertyDescriptor(_obj, "moreSettings"), _obj)), _obj));
});